<template>
  <div class="supplydemand">
    <div class="supplydemand-left">
        <div class="supplydemand-left-header">
        <div class="info_header_left">我的供需</div>
         <div class="message-totle">共 {{ total }} 条</div>
      </div>
     
      

     
      <div class="main-body">
         <div v-if="total == 0" class="empty-box">
         <div class="img-empty-wrap">
          <!-- <i class="el-icon-document"></i> -->
         </div>
         <div class="content-slot-wrap">这里什么都没有</div>
      </div>
        <div v-else
          class="main-body-items"
          v-for="(item, index) in Supplylist"
          :key="index"
          @click="godetails(item, 3)"
        >
          <div class="title" >
            <!-- 多个三元 -->
            <!-- 条件?'' :条件? '' : '' -->
            <!-- <span
              :class="
                item.articleFields[1].fieldValue == '1'
                  ? 'gong'
                  : item.articleFields[1].fieldValue == '2'
                  ? 'xu'
                  : 'pin'
              "
              class="title-icon"
              >{{
                item.articleFields[1].fieldValue == "1"
                  ? "供"
                  : item.articleFields[1].fieldValue == "2"
                  ? "需"
                  : "聘"
              }}</span
            > -->
            {{ item.title }}
          </div>
          <div class="author-details">
            <!-- <div class="author-box" v-if="item.author">
              <img class="author-img" src="@/assets/images/weibo2.png" alt="" />
              <p>{{ item.author }}</p>
            </div> -->
            <!-- <div class="button-share">
              <p>分享 <i class="iconfont icon-arrow-"></i></p>
            </div> -->
          </div>
          <div class="textarea-box">
            <div class="img-box">
              <img :src="global.imgUrl + item.imgUrl" alt="" />
            </div>
            <div class="textarea-details">
              <p @click="godetails(item, 3)">
                {{ item.zhaiyao }}
              </p>
            </div>
          </div>
          <!-- <div class="textarea-date-box">
               <div>{{ item.addTime.slice(0, 11) }}</div>
           <div class="Operationtype">
        
              
              <div @click.stop="shoucang(item.id)">
               
                 <img :src="imgIcon.imgCang">
                <span class="count">{{ item.favCount }}</span>
              </div>
            </div>
         
          </div> -->
            <div class="author-details">
                <div class="author-box">
                  <p class="author-name" v-if="item.author">
                    {{ item.author }}
                  </p>
                  <div class="author-time">
                    {{ item.addTime.slice(0, 11) }}
                  </div>
                </div>
                <div class="Operationtype">
                  <!-- <div @click.stop="dianzan(item.id)">
                    <img :src="imgIcon.imgZan" />
                    <span class="count">{{ item.likeCount }}</span>
                  </div> -->
                  
                  <div @click.stop="shoucang(item.id)">
                    <!-- <i class="iconfont icon-xiai"></i> -->
                    <img :src="imgIcon.imgCangTrue" />
                    <span class="count">已收藏</span>
                  </div>
                 
                </div>
              </div>
        </div>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
   
  </div>
</template>

<script>
import {
  getSupplylist,
  getSupplyclassification,
  getSupplyclassifyid,
  gethotsupply,
  fav,
  putFav,
} from "@/api";
import { imgObj } from '@/utils/const.js';
export default {
  name: "supplydemand",
  data() {
    return {
      classifytext: "热门",
      classifylist: [],
      resultlist: [],
      resulttext: "全部",
      currentPage: 1,
      peopletype: "最新加入",
      Supplylist: [],
      total: 0,
      hotsupplylist: [],
      dialogVisible: false,
       imgIcon:imgObj
    };
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
    },
    showrelease() {
      let token = window.localStorage.getItem("token");
      let username = window.localStorage.getItem("userName");
      
      
      this.$router.push("/release");
    


      // if (token && username) {
      //   this.$router.push("/release");
      // } else {
      //   this.$message({
      //     message: "请先登录后，才能发布供需",
      //     type: "warning",
      //   });
      //   setTimeout(() => {
      //     location.reload();
      //   }, 1000);
      // }
    },
    getFav(){
        fav().then((res) => {
            console.log(res)
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
    },
    dianzan(articleId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        putLike(articleId).then((res) => {
          console.log(res);
          this.getFav();
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
        //    this.router.replace({
        //   name: "/login",
        //   query: { redirect: router.currentRoute.name }
        // });
      }
    },
    shoucang(articleId) {
      let username = window.localStorage.getItem("userName");
      if (username) {
        putFav(articleId).then((res) => {
          console.log(res);
          this.getFav();
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能收藏",
          type: "warning",
        });
      }
    },
    handleSizeChange(e) {
      console.log(e);
    },
    handleCurrentChange(e) {
      console.log(e);
    },
    changefirstbtn(item, index) {
      this.classifytext = item.title;
      this.resultlist = this.classifylist[index].children;
      this.resulttext = "全部";
      this.changelist(item.id);
    },
    changesecondbtn(item) {
      this.resulttext = item.title;
      this.changelist(item.id);
    },
    changelist(id) {
      getSupplyclassifyid(id).then((res) => {
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`);
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id,
          type,
        },
      });
      window.open(routeData.href, "_blank");
    },
    start() {
      getSupplyclassifyid(358).then((res) => {
        console.log("供需类别列表", res);
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
      getSupplyclassification().then((res) => {
        console.log("供需分类", res);
        this.classifylist = res.data;
        this.resultlist = res.data[0].children;
      });
      gethotsupply().then((res) => {
        console.log("热门供需", res);
        this.hotsupplylist = res.data;
      });
    },
  },
  mounted() {
    // this.start();
    this.getFav()
  },
};
</script>

<style lang="less" scoped>
.supplydemand {
//   width: 1280px;
     padding: 0.625rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  .supplydemand-left {
    width: 100%;
    margin-right: 20px;
    .supplydemand-left-header {
     height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
    }
    .message-totle {
      // padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }
    .main-body {
      background: white;
      .empty-box{
        padding: 4rem 0;
            text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .img-empty-wrap{
          display: block;
    width: 180px;
    height: 180px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    // font-size: 65px;
     color: #8a919f;
      background-image:url("https://mcnki.obs.cn-east-2.myhuaweicloud.com/img/empty.png")
    }
    .content-slot-wrap{
      font-size: 14px;
    font-weight: 400;
    color: #8a919f;
    }
      }
      .main-body-items {
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #e9e9e9;
        .title {
          font-size: 18px;
          font-weight: bold;
          
          .title-icon {
            font-size: 12px;
            padding: 2px;
            position: relative;
            top: -2px;
            font-weight: normal;
            color: white;
          }
          .gong {
            background: #314483;
          }
          .xu {
            background: #28b28b;
          }
          .pin {
            background: #8169ff;
          }
        }
        .author-details {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 14px;
          color: #666;
          line-height: 12px;
          align-items: center;
          .author-box {
            display: flex;
            // justify-content: space-between;
            .author-name {
              padding: 0.1875rem 0.625rem;
              line-height: 1.25rem;
              text-align: center;
              background: #f5f5f5;
              color: #314483;
              // border: 0.0625rem solid #314483;
              // margin-left: 0.625rem;
            }
            .author-time {
              line-height: 1.7rem;
              margin-left: 20px;
                  display: flex;
    align-items: center;
            }
          }
          .button-share {
            p {
              height: 24px;
              line-height: 24px;
              margin: 0;
              font-size: 11px;
              background: #f5f6f6;
              padding: 2px 0px 2px 12px;
              border-radius: 15px;
              cursor: pointer;
              .iconfont {
                margin-left: 5px;
                background: #d8d8d8;
                font-size: 15px;
                padding: 7px;
                color: white;
                border-radius: 50%;
                cursor: pointer;
                right: 0;
              }
            }
          }
          .author-img {
            margin-top: 7px;
            margin-right: 10px;
            width: 24px;
            height: 24px;
          }
          .Operationtype {
            display: flex;
            font-size: 14px;
            color: #999;
            cursor: pointer;
            div {
              padding: 10px 25px;
              background: #fff;
              color: #525252;
              font-size: 14px;
              margin-right: 20px;
              border: 1px solid #ededed;
              display: flex;
              align-items: flex-end;
              span {
                margin-left: 10px;
              }
            }
            // div:nth-child(1) {
            //   padding: 10px 20px;
            //   background: #f5f6f6;
            //   color: #314483;
            //   font-size: 14px;
            // }
            // div {
            //   padding: 10px;
            //   margin-right: 20px;
            // }
            .count {
              margin-left: 10px;
            }
          }
        }
        .textarea-box {
          display: flex;
          font-size: 15px;
          color: #333;
          .img-box {
            img {
              width: 178px;
            }
          }
        }
        .textarea-details {
          padding-left: 10px;
          p {
            margin: 0;
            line-height: 25px;
          }
          p:nth-child(2) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
        .textarea-date-box {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          div:nth-child(2) {
            font-size: 14px;
            color: #999;
          }
            .Operationtype {
              display: flex;
              font-size: 14px;
              color: #999;
              cursor: pointer;
              div {
                padding: 10px 25px;
                background: #fff;
                color: #525252;
                font-size: 14px;
                margin-right: 20px;
                border: 1px solid #EDEDED;
                display: flex;
    align-items: flex-end;
                span {
                  margin-left: 10px;
                }
              }
              // div:nth-child(1) {
              //   padding: 10px 20px;
              //   background: #f5f6f6;
              //   color: #314483;
              //   font-size: 14px;
              // }
              // div {
              //   padding: 10px;
              //   margin-right: 20px;
              // }
              .count {
                margin-left: 10px;
              }
            }
        }
      }
      .main-body-items:hover{
        background: #e9e9e973;
      }
    }
  }
 
  .el-pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #314483;
    }
    /deep/ li:hover {
      color: white;
      background: #314483;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #314483;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>